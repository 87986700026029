import axios from 'axios';
import { Job } from '../types/job';

const API_BASE_URL = 'http://localhost:5001';

interface JobsResponse {
  jobs: Job[];
  total_jobs: number;
  page: number;
  limit: number;
}

export async function fetchJobs(page: number = 1, limit: number = 20): Promise<JobsResponse> {
  try {
    const response = await axios.get<JobsResponse>(`${API_BASE_URL}/jobs`, {
      params: { page, limit }
    });
    return response.data;
  } catch (error) {
    console.error('Error fetching jobs:', error);
    throw error;
  }
}

export const searchJobs = async (query: string, company: string, category: string, page: number, limit: number): Promise<JobsResponse> => {
  try {
    const response = await axios.get<JobsResponse>(`${API_BASE_URL}/jobs/search`, {
      params: { q: query, company, category, page, limit }
    });
    return response.data;
  } catch (error) {
    console.error('Error searching jobs:', error);
    throw error;
  }
};

export async function getJobById(id: string): Promise<Job> {
  try {
    const response = await axios.get<Job>(`${API_BASE_URL}/jobs/${id}`);
    return response.data;
  } catch (error) {
    console.error('Error fetching job details:', error);
    throw error;
  }
}